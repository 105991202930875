// Não se esqueça de recompilar os scripts para poder ver as alterações feitas aqui!
//
// Você pode fazer isso pelo terminal de duas maneiras:
//
// npm run dev
// *Irá recompilar uma única vez todos os estilos e scripts do projeto*
//
// npm run watch-poll
// *Irá recompilar todos os estilos e scripts do projeto a primeira vez, e depois continuará rodando de fundo, e cada vez que você alterar um arquivo CSS ou JS e salvar, 
//  ele irá compilar apenas o que você alterou!*

(function ($) {
    $(document).ready(function () {
        $('#cssmenu2 li.has-sub>a').on('click', function () {
            $(this).removeAttr('href');
            var element = $(this).parent('li');
            if (element.hasClass('open')) {
                element.removeClass('open');
                element.find('li').removeClass('open');
                element.find('ul').slideUp();
            }
            else {
                element.addClass('open');
                element.children('ul').slideDown();
                element.siblings('li').children('ul').slideUp();
                element.siblings('li').removeClass('open');
                element.siblings('li').find('li').removeClass('open');
                element.siblings('li').find('ul').slideUp();
            }
        });

        $('#cssmenu2>ul>li.has-sub>a').append('<span class="holder"></span>');


        function rgbToHsl(r, g, b) {
            r /= 255, g /= 255, b /= 255;
            var max = Math.max(r, g, b), min = Math.min(r, g, b);
            var h, s, l = (max + min) / 2;

            if (max == min) {
                h = s = 0;
            }
            else {
                var d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                    case g: h = (b - r) / d + 2; break;
                    case b: h = (r - g) / d + 4; break;
                }
                h /= 6;
            }
            return l;
        }
    });
})(jQuery);

$("#ajax_form").submit(function () {
    event.preventDefault();
    var formulario = $(this);
    formulario.hide();
    $("#loading").show();
    var destination = $(this).attr('action');
    $.ajax({
        type: "POST",
        url: destination,
        data: $(this).serialize(),
        dataType: "json",
        success: function (response) {
            window.location = "/contato/obrigado"
            formulario.get(0).reset();
            formulario.show();
            $("#loading").hide();
        },
        error: function (response) {
            alert("Ocorreu um erro ao enviar seu e-mail. Por favor, tente novamente.");
            formulario.show();
            $("#loading").hide();
            console.log(response);
        }
    });
});

$('#owl-sobre').owlCarousel({
    navText: [
        "<ion-icon class='chevron-back-outline icone'>",
        "<ion-icon class='chevron-forward-outline icone'>"
    ],
    loop: true,
    autoplay: 6000,
    autoplaySpeed: 2000,
    slideSpeed: 3000,
    paginationSpeed: 6000,
    margin: 0,
    responsiveClass: true,
    nav: true,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 1,
        },
        1500: {
            items: 2,
        }
    }

});

$('#owl-blog').owlCarousel({
    loop: true,
    autoplay: 6000,
    autoplaySpeed: 2000,
    slideSpeed: 3000,
    paginationSpeed: 6000,
    margin: 0,
    responsiveClass: true,
    nav: false,
    responsive: {
        0: {
            items: 1,
        },
        770: {
            items: 2,
        },
        990: {
            items: 3,
        }
    }

});

